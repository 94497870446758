import React from "react"
import Form from "../form"
import { FormField } from "../form-field"
import FormWrapper from "./form-wrapper"


const NeedInfoForm = () => {

    return (
        <FormWrapper title='Need more information ?'>
            <Form
                subject='Website form : Need more information'
            >{
                    ({ handleChange, handleBlur, values }) => (
                        <>
                            <FormField
                                label='Name'
                                htmlFor='name'
                                required
                            >
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Your name"
                                    name='name'
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                            </FormField>
                            <FormField
                                label='Email'
                                htmlFor='email'
                                required
                            >
                                <input
                                    className="input"
                                    name='email'
                                    type="email"
                                    placeholder="Your email address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                            </FormField>
                            <FormField
                                label='Subject'
                                htmlFor='subject'
                                required
                            >
                                <input
                                    className="input"
                                    name='subject'
                                    type="text"
                                    placeholder="Subject of your message"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.subject}
                                />
                            </FormField>
                            <FormField
                                label='Message'
                                htmlFor='message'
                                required
                            >
                                <textarea
                                    className="textarea"
                                    name='message'
                                    placeholder="Your message"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.message}
                                />
                            </FormField>
                        </>
                    )
                }
            </Form>
        </FormWrapper>
    )
}
export default NeedInfoForm