import React from 'react'
import { Element } from 'react-bulma-components'

const FormField = ({ label, htmlFor, required, children }) => {

    return (
        <div className="field is-horizontal">
            <div className='field-label is-normal'>
                <label className="label" htmlFor={htmlFor}>
                    {label}
                    {required && <Asterisk />}
                </label>
            </div>
            <div className='field-body'>
                <div className="field">
                    <div className="control">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
const Asterisk = () => <Element renderAs='span' textWeight='bold' textSize={6}>*</Element>

export { FormField, Asterisk }