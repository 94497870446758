import React from "react"
import { Formik } from "formik"
import { Asterisk, FormField } from "./form-field"
import { Element } from "react-bulma-components"
import { EosButton } from "../eos"

const SubmitButton = ({ status }) => {

    // status = 'submit' | 'submitting' | 'submitted
    
    return (
        <EosButton
            renderAs='input'
            type='submit'
            color={
                status === 'submitted' ? 'secondary' : 'cta'
            }
            value={
                status === 'submitting' ? 'Submitting' : status === 'submitted' ? 'Message sent' : 'Submit'
            }
            disabled={status !== 'submit'}
        />
    )
}

const Form = ({ subject, children }) => {

    const handleSubmit = (values, { setSubmitting }) => {
        setTimeout(() => {
            //fetch("https://formsubmit.co/ajax/cb2d1ac9691d4f0df721cfb1b28e1347", { // Email tom encrypted
            fetch("https://formsubmit.co/ajax/2e4c124b981ed37ae34ae956af8ba239", { // homeeos
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(values)
            })
                .then(response => response.json())
                .then(data => console.log(data))
                .catch(error => console.log(error))
            setSubmitting(false);
        }, 400);
    }

    return (

        <Formik
            initialValues={{
                name: '',
                email: '',
                subject: '',
                message: '',
                _subject: subject || "EOS Tech form submission",
                _autoresponse: "Your form submission on EOS Technology website"
            }}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <form onSubmit={props.handleSubmit}>
                    {children(props)}
                    <FormField
                        label=''
                    >
                        <SubmitButton status={props.isSubmitting ? 'submitting' : props.submitCount > 0 ? 'submitted' : 'submit'} />
                    </FormField>
                    <FormField
                        label=''
                    >
                        <Element renderAs='p' textSize={7}><Asterisk /> field is required</Element>
                    </FormField>
                    <input type="hidden" name="_cc" value="dev@codefathers.be" />
                    <input type="text" name="_honey" style={{ display: 'none' }} />
                    <input type="hidden" name="_autoresponse" value="Thank you for contacting EOS Technology !"></input>
                </form>
            )}
        </Formik>
    )
}
export default Form