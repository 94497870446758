import React from 'react'
import { EosHeading, EosSection } from '../../eos'

const FormWrapper = ({ title, children }) => {

    return (

        <EosSection backgroundColor='white-ter' style={{ minWidth: 'calc(min(100vw, 600px) - 2rem)' }}>
            <EosHeading
                size={4}
                textColor='primary'
                decoration='primary'
                textWeight='bold'
            >
                {title}
            </EosHeading>
            {children}
        </EosSection>
    )
}
export default FormWrapper