import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Box, Button, Columns, Element } from 'react-bulma-components'
import { EosHeading, EosSection, EosHero, EosModal, eosCtaButtonAction } from "../components/eos"
import MiddlePageCta from "../components/sections/middle-page-cta-section"
import OurLabelsSection from "../components/sections/our-labels-section"
import { StaticImage } from "gatsby-plugin-image"
import PartnerForm from "../components/forms/templates/looking-for-partner-form"
import NeedInfoForm from "../components/forms/templates/need-info-form"
import WorkWithUsForm from "../components/forms/templates/work-with-us-form"
import EndPageCtaSection from "../components/sections/end-page-cta-section"

const ContactButton = ({ picto, pictoHover, label, id, ...rest }) => {

    const [isHovered, setIsHovered] = useState(false)
    const isActive = isHovered

    return (
        <Button renderAs={Box} style={{ height: 'auto' }}
            color={isActive ? 'primary' : 'white'}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...rest}
        >
            <Element my={5} style={{ pointerEvents: 'none' }}>
                {isActive ? pictoHover : picto}
            </Element>
            <EosHeading
                size={5}
                decoration={false}
                textTransform='uppercase'
                textWeight='bold'
                textColor={isActive ? 'white' : 'primary'}
                mb={5}
            >
                {label}
            </EosHeading>
        </Button>
    )
}

const ContactPage = () => {

    const { t } = useTranslation(['page_home', 'slugs'])
    const [active, setActive] = useState(undefined)
    const [isDisplayed, setIsDisplayed] = useState(false)

    const tMeta = t('meta')
    const tSlugs = t('slugs:slugs')

    const handleModal = (content) => {
        setActive(content)
        setIsDisplayed(true)
    }

    return (
        <Layout style={{ backgroundColor: '#f9f9f9' }}>
            <Seo
                title={tMeta.title}
                description={tMeta.description}
            />
            <EosModal
                isDisplayed={isDisplayed}
                setIsDisplayed={setIsDisplayed}
            >
                {
                    active === 'info'
                        ?
                        <NeedInfoForm />
                        : active === 'partner'
                            ?
                            <PartnerForm />
                            :
                            <WorkWithUsForm />
                }
            </EosModal>
            <EosHero
                title='Contact'
                subtitle='Lorem ipsum dolor sit amet'
                backgroundImageUrl={'/images/news.jpg'}
                size="medium"
            />

            {
                /* 
                ** Contact Actions
                */
            }
            <EosSection>
                <EosHeading>We'd love to hear from you</EosHeading>
                <Columns multiline={false}>
                    <Columns.Column>
                        <ContactButton
                            picto={<StaticImage src='../../static/pictos/group.svg' placeholder='none' />}
                            pictoHover={<StaticImage src='../../static/pictos/group-hover.svg' placeholder='none' />}
                            label={'Request a meeting'}
                            onClick={eosCtaButtonAction}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <ContactButton
                            picto={<StaticImage src='../../static/pictos/shaking-hands.svg' placeholder='none' />}
                            pictoHover={<StaticImage src='../../static/pictos/shaking-hands-hover.svg' placeholder='none' />}
                            label={'Looking for a partner'}
                            onClick={() => handleModal('partner')}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <ContactButton
                            picto={<StaticImage src='../../static/pictos/information.svg' placeholder='none' />}
                            pictoHover={<StaticImage src='../../static/pictos/information-hover.svg' placeholder='none' />}
                            label={'Need informations'}
                            onClick={() => handleModal('info')}
                        />
                    </Columns.Column>
                    <Columns.Column>
                        <ContactButton
                            picto={<StaticImage src='../../static/pictos/person.svg' placeholder='none' />}
                            pictoHover={<StaticImage src='../../static/pictos/person-hover.svg' placeholder='none' />}
                            label={'Work with us'}
                            onClick={() => handleModal('work')}
                        />
                    </Columns.Column>
                </Columns>
            </EosSection>
            <EosSection style={{ paddingBottom: '12rem' }}>
                <Columns gap={8}>
                    <Columns.Column>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2540.6391627694766!2d4.529357716106245!3d50.44782147947495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2293909e2e2e5%3A0xce5c50941df3c011!2sHome%20Eos!5e0!3m2!1sfr!2sbe!4v1637251627664!5m2!1sfr!2sbe" width="600" height="450" style={{ border: 0, width: '100%', height: '100%' }} allowfullscreen="" loading="lazy"></iframe>
                    </Columns.Column>
                    <Columns.Column>
                        <EosHeading decoration='primary' size={3}>Our office</EosHeading>
                        <p>
                            Eos Technology by Home Eos<br />
                            Rue de Fontenelle 2<br />
                            B – 6240 Farciennes<br />
                            Belgium<br /><br />
                            +32 71 37 63 80<br />
                            info@home-eos.eu<br /><br />
                            TVA : BE 0548.933.490

                        </p>
                    </Columns.Column>
                </Columns>
            </EosSection>


            {
                /* 
                ** MIDDLE PAGE CTA 
                */
            }
            <MiddlePageCta />
            <OurLabelsSection />
        </Layout >
    )
}

export default ContactPage

export const query = graphql`
                query($language: String!) {
                    locales: allLocale(filter: {language: {eq: $language}}) {
                    edges {
                    node {
                    ns
          data
                language
        }
      }
    }
  }
                `;